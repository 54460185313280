import React from 'react';
import Link from 'next/link';
import SocialIconList from './social-icon-list';
import Container from './container';
import config from '../lib/config';

import { Label, Muted } from './text';
import { LinkButton } from './link-button';
import ConsentModal from './consent-modal';
import { useEligibility } from '@/context/base-context';

export default function FooterLinks() {
	const { setShowOptOutModal } = useEligibility();
	return (
		<>
			<footer className="bg-white pb-4">
				<Container>
					<div>
						<div className="block py-8 md:flex md:flex-row md:items-center md:justify-between lg:justify-around">
							<SocialIconList />
							<div className="flex flex-col items-center py-12 gap-4">
								<Label>Schrijf je in voor de nieuwsbrief</Label>
								<LinkButton primary size={'small'} href="/nieuwsbrief">
									Inschrijven
								</LinkButton>
							</div>
						</div>
					</div>

					<div className="border-t border-gray-900/10 pt-4  xl:py-2 xl:flex xl:items-start xl:justify-between">
						<nav className="" aria-label="Footer">
							<div className="pb-2 xl:pb-0">
								<Link
									href={'/algemene-voorwaarden'}
									className="uppercase font-inter tracking-wider text-xs text-[#302E3B] hover:text-gray-800"
								>
									Algemene voorwaarden
								</Link>
							</div>
							<div className="pb-2 xl:pb-0">
								<Link
									href={'/cookies'}
									className="uppercase font-inter tracking-wider text-xs text-[#302E3B] hover:text-gray-800"
								>
									Cookies
								</Link>
							</div>
							<div className="pb-2 xl:pb-0">
								<Link
									href={'/privacy-verklaring'}
									className="uppercase font-inter tracking-wider text-xs text-[#302E3B] hover:text-gray-800"
								>
									Privacy verklaring
								</Link>
							</div>
							<div className="pb-2 xl:pb-0">
								<Link
									href={'/responsible-betting'}
									className="uppercase font-inter tracking-wider text-xs text-[#302E3B] hover:text-gray-800"
								>
									Responsible Betting Partnership: Eerlijk is Eerlijk.
								</Link>
							</div>
							<div className="pb-2 xl:pb-0">
								<Link
									href={'/cybercontact'}
									className="uppercase font-inter tracking-wider text-xs text-[#302E3B] hover:text-gray-800"
								>
									Cybercontact
								</Link>
							</div>
							<div className="pb-2 xl:pb-0">
								<Link
									href="#"
									onClick={(e) => {
										e.preventDefault();
										setShowOptOutModal(true);
									}}
									className="uppercase font-inter tracking-wider text-xs text-[#302E3B] hover:text-gray-800"
								>
									Opt-out voor kansspel reclame
								</Link>
							</div>
						</nav>
						<Muted className="w-full xl:w-auto text-center xl:text-left text-xs leading-5 py-5">
							&copy; {new Date().getFullYear()} {config.siteName}
						</Muted>
					</div>
				</Container>
			</footer>
		</>
	);
}
