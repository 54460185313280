import React from 'react';
import cn from 'classnames';

interface IProps {
	children: React.ReactNode;
	className?: string;
	noPaddingMobile?: boolean;
}

export default function Container({ children, className, noPaddingMobile }: IProps) {
	const classNames = cn(
		'container mx-auto',
		noPaddingMobile ? 'sm:px-5' : 'px-5',
		className,
	);
	return <div className={classNames}>{children}</div>;
}
