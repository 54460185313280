import React from 'react';
import Link from 'next/link';
import { BsFacebook, BsInstagram, BsYoutube, BsTiktok } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';

import { Label } from './text';

export default function SocialIconList() {
	return (
		<div className="flex flex-col items-center py-12 gap-4">
			<Label>Volg ons op Social Media</Label>
			<ul role="list" className="flex gap-6">
				<li className="col-span-1">
					<Link target="_blank" href="https://www.facebook.com/keukenkampioendivisie">
						<BsFacebook className="w-8 h-8 hover:cursor-pointer hover:fill-black-200 transition-all duration-200" />
					</Link>
				</li>
				<li className="col-span-1">
					<Link target="_blank" href="https://twitter.com/1e_Divisie">
						<FaXTwitter className="w-8 h-8 hover:cursor-pointer hover:fill-black-200 transition-all duration-200" />
					</Link>
				</li>
				<li className="col-span-1">
					<Link target="_blank" href="https://www.instagram.com/keukenkampioendivisie">
						<BsInstagram className="w-8 h-8 hover:cursor-pointer hover:fill-black-200 transition-all duration-200" />
					</Link>
				</li>
				<li className="col-span-1">
					<Link target="_blank" href="https://www.youtube.com/KeukenKampioenDivisie">
						<BsYoutube className="w-8 h-8 hover:cursor-pointer hover:fill-black-200 transition-all duration-200" />
					</Link>
				</li>
				<li className="col-span-1">
					<Link target="_blank" href="https://www.tiktok.com/@keukenkampioendivisie">
						<BsTiktok className="w-8 h-8 hover:cursor-pointer hover:fill-black-200 transition-all duration-200" />
					</Link>
				</li>
			</ul>
		</div>
	);
}
