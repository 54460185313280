import React, { useMemo } from 'react';
import Link from 'next/link';

import { ILogoImages } from '../models/logo';

interface IProps {
	logoImages: ILogoImages[] | null;
}

export default function LogoRow({ logoImages }: IProps) {
	const mappedLogos = useMemo(
		() =>
			logoImages?.filter((logo) => {
				const excludedSlugs = [
					'almere-city-fc',
					'pec-zwolle',
					'heracles-almelo',
					'nac-breda',
					'achilles-29',
					'fortuna-sittard',
					'sparta-rotterdam',
					'rkc-waalwijk',
					'fc-twente',
					'go-ahead-eagles',
					'n-e-c',
					'fc-groningen',
					'willem-ii',
				];
				return logo.team_logo && !excludedSlugs.includes(logo.slug);
			}),
		[logoImages],
	);

	return (
		<div className="w-full bg-white">
			<div className="container mx-auto px-5 hidden lg:flex flex-row justify-between items-center bg-white">
				{mappedLogos?.map((logo) => (
					<div key={logo.id} className="flex flex-col justify-center items-center m-3">
						<Link href={`/teams/${logo.slug}`}>
							<img
								src={logo.team_logo}
								className="w-10 h-10 object-contain transition-opacity hover:opacity-75"
								alt={logo.title.rendered}
							/>
						</Link>
					</div>
				))}
			</div>
		</div>
	);
}
