import React, { HTMLAttributeAnchorTarget } from 'react';
import Icon, { Icons } from './icon/Icon';
import Link from 'next/link';

export interface LinkButtonProps {
	href: string;
	children: React.ReactNode;
	onClick?: () => void;
	primary?: boolean;
	size?: 'small' | 'medium' | 'large';
	icon?: Icons;
	target?: HTMLAttributeAnchorTarget;
	className?: string;
}

export const LinkButton = ({
	primary = false,
	size = 'medium',
	icon,
	children,
	href,
	className,
	...props
}: LinkButtonProps) => {
	const mode = primary ? 'kkd-button--primary' : 'kkd-button--secondary';
	const hasIcon = icon ? 'flex items-center' : 'inline-block';

	return (
		<Link
			href={href || '/'}
			className={[
				hasIcon,
				'kkd-button',
				`kkd-button--${size}`,
				`${className}`,
				mode,
			].join(' ')}
			{...props}
		>
			<span className={icon ? 'inline-block mr-3' : ''}>{children}</span>
			{icon && (
				<Icon
					icon={icon}
					color={primary ? 'white' : 'text-black-500'}
					size={size === 'small' ? 16 : 18}
					className="inline-block"
				/>
			)}
		</Link>
	);
};
