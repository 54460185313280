import React from 'react';
import Link from 'next/link';
import Container from './container';
import { ISponsor } from '../models/sponsor';
import { Label } from './text';
import classNames from 'classnames';

interface IProps {
	sponsors: ISponsor[];
}

export default function Footer({ sponsors }: IProps) {
	const mainSponsors = sponsors?.filter((sponsor) => sponsor.sponsor_fields.mainSponsor);
	const remainingSponsors = sponsors?.filter((sponsor) => !sponsor.sponsor_fields.mainSponsor);

	const trimText = (text: string) => {
		const words = text.split(' ');
		return words[0].toLowerCase();
	};

	return (
		<footer className="footer-sponsors bg-kkd-semi-dark lg:px-32 py-12 divide-y divide-gray-500 ">
			<section className="py-6 lg:py-12">
				<Container>
					<div className="flex flex-wrap justify-center">
						{mainSponsors?.map((sponsor, i) => (
							<div
								key={i}
								className={classNames(
									trimText(sponsor.sponsor_fields.subtitle),
									`mb-6 lg:mb-8 w-full sm:w-1/3 lg:w-1/4 p-2`,
								)}
							>
								<Link href={sponsor.sponsor_fields.websiteUrl} target="_blank">
									<div className="flex flex-col items-center">
										<img
											src={sponsor.sponsor_fields.invertedImage?.sourceUrl}
											alt={sponsor.sponsor_fields.subtitle}
											className="p-4 mb-2 h-24 object-contain md:h-32 md:object-contain w-full transition-opacity hover:opacity-70"
										/>
										<Label className="uppercase font-inter tracking-wider text-xs text-gray-500 hover:text-gray-800">
											{sponsor.sponsor_fields.subtitle}
										</Label>
									</div>
								</Link>
							</div>
						))}
					</div>
				</Container>
			</section>

			<section className="py-12">
				<Container>
					<div className="flex flex-wrap justify-center">
						{remainingSponsors?.map((sponsor, i) => (
							<div
								key={i}
								className="flex justify-center items-center mb-6 lg:mb-8 w-full sm:w-1/2 md:w-1/4 p-2 hover:cursor-pointer"
							>
								<Link href={sponsor.sponsor_fields.websiteUrl} target="_blank">
									<div className="flex flex-col items-center">
										<img
											src={sponsor.sponsor_fields.invertedImage?.sourceUrl}
											alt={sponsor.sponsor_fields.subtitle}
											className="mb-4 h-6 lg:h-11 lg:object-contain transition-opacity hover:opacity-70 brightness-75"
										/>
										<Label className="uppercase font-inter tracking-wider text-xs text-gray-500 hover:text-gray-800">
											{sponsor.sponsor_fields.subtitle}
										</Label>
									</div>
								</Link>
							</div>
						))}
					</div>
				</Container>
			</section>
		</footer>
	);
}
