import { useEffect, useState } from 'react';
import { ILogoImages } from '../models/logo';

import config from '../lib/config';

const useLogoImages = () => {
	const [logoImages, setLogoImages] = useState<ILogoImages[] | null>(null);

	useEffect(() => {
		const fetchLogoImages = async () => {
			try {
				const endpoint = `${config.apiUrl}/wp-json/wp/v2/teams?per_page=40&order=asc&orderby=title`;
				const res = await fetch(endpoint);
				const data = await res.json();
				setLogoImages(data);
			} catch (error) {
				console.error('Error fetching logo images:', error);
				setLogoImages(null);
			}
		};

		fetchLogoImages();
	}, []);

	return logoImages;
};

export default useLogoImages;
