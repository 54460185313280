import React from 'react';
import Head from 'next/head';
import Script from 'next/script';

export interface IMetaProps {
	metaTitle?: string;
	metaDesc?: string;
}

export default function Meta({ metaTitle, metaDesc }: IMetaProps) {
	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
				/>
				<meta
					name="description"
					content="Volg hier al het nieuws, uitslagen en samenvattingen over de Keuken Kampioen Divisie."
				/>
				<meta property="og:locale" content="nl_NL" />
				<meta property="og:type" content="website" />
				<meta
					property="og:title"
					content={
						metaTitle || 'Keuken Kampioen Divisie: Het laatste nieuws, uitslagen, standen en samenvattingen'
					}
				/>
				<meta
					property="og:description"
					content={
						metaDesc ||
						'Volg hier al het nieuws, uitslagen en samenvattingen over de Keuken Kampioen Divisie.'
					}
				/>
				<meta property="og:site_name" content="Keuken Kampioen Divisie" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:description"
					content={
						metaDesc ||
						'Volg hier al het nieuws, uitslagen en samenvattingen over de Keuken Kampioen Divisie.'
					}
				/>
				<meta name="twitter:title" content={metaTitle || 'Keuken Kampioen Divisie'} />
				<meta name="twitter:site" content="@1e_Divisie" />
				<meta name="twitter:creator" content="@1e_Divisie" />
				<meta name="msapplication-TileColor" content="#000000" />
				<meta name="msapplication-config" content="/favicon/browserconfig.xml" />
				<meta name="theme-color" content="#E2002B" />
				<link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/favicon/site.webmanifest" />
				<link rel="shortcut icon" href="/favicon/favicon.ico" />
			</Head>
			<Script
				type="text/javascript"
				data-cmp-ab="1"
				src="https://cdn.consentmanager.net/delivery/autoblocking/0858d302711b7.js"
				data-cmp-host="d.delivery.consentmanager.net"
				data-cmp-cdn="cdn.consentmanager.net"
				data-cmp-codesrc="0"
			></Script>
			<Script
				type="text/javascript"
				src="https://stats.skillsourcehosting.nl/kkd/ebtm.min.js"
				defer
				data-cmp-vendor="c44429"
			></Script>
		</>
	);
}
