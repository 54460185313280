import React, { ElementType, HTMLAttributes } from 'react';
import { types } from 'util';

interface IProps extends HTMLAttributes<HTMLElement> {
	// as?: keyof JSX.IntrinsicElements;
	// as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';
	as?: ElementType;
	className?: string;
	children?: React.ReactNode;
	// maybe we can use a union type here
	color?: string;
}

// Todo DEVP-140: Add responsive sizes to text
export function Text({ as: Tag = 'span', className = '', children, color = 'text-black-500', ...props }: IProps) {
	let classnames = `antialiased ${className} ${color}`;
	return (
		<Tag className={classnames} {...props}>
			{children}
		</Tag>
	);
}

export function H1({ as = 'h1', children, className, ...props }: IProps) {
	return (
		<Text
			as={as}
			className={`font-theinhardt text-4xl md:text-6xl lg:text-7xl font-bold tracking-tighter leading-tight md:leading-none ${className}`}
			{...props}
		>
			{children}
		</Text>
	);
}

export function H2({ as = 'h2', children, className, ...props }: IProps) {
	return (
		<Text as={as} className={`font-theinhardt text-5xl lg:text-6xl font-bold ${className}`} {...props}>
			{children}
		</Text>
	);
}

export function H3({ as = 'h3', children, className, ...props }: IProps) {
	return (
		<Text as={as} className={`font-theinhardt text-lg lg:text-4xl font-bold ${className}`} {...props}>
			{children}
		</Text>
	);
}

export function H4({ as = 'h4', children, className, ...props }: IProps) {
	return (
		<Text as={as} className={`font-theinhardt font-bold text-base md:text-xl lg:text-3xl ${className}`} {...props}>
			{children}
		</Text>
	);
}

export function H5({ as = 'h5', children, className, ...props }: IProps) {
	return (
		<Text as={as} className={`font-theinhardt font-bold text-xl lg:text-2xl ${className}`} {...props}>
			{children}
		</Text>
	);
}

export function H6({ as = 'h6', children, className, ...props }: IProps) {
	return (
		<Text as={as} className={`font-theinhardt font-bold text-xl ${className}`} {...props}>
			{children}
		</Text>
	);
}

export function P({ as = 'p', children, className, ...props }: IProps) {
	return (
		<Text as={as} className={`font-theinhardt font-normal text-lg leading-relaxed ${className}`} {...props}>
			{children}
		</Text>
	);
}

export function Label({ as = 'span', children, className, ...props }: IProps) {
	return (
		<Text
			as={as}
			className={`font-inter text-base font-bold inline-block uppercase tracking-wide ${className}`}
			{...props}
		>
			{children}
		</Text>
	);
}

export function Muted({ as = 'span', color = 'text-black-200', className, children, ...props }: IProps) {
	return (
		<Text
			as={as}
			color={color}
			className={`font-inter font-bold text-base inline-block tracking-wide ${className}`}
			{...props}
		>
			{children}
		</Text>
	);
}
