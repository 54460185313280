import React from 'react';
import Icon from './icon/Icon';
import Link from 'next/link';
import { LinkButtonProps } from './link-button';

interface LinkButtonPropsExtended extends Omit<LinkButtonProps, 'primary' | 'size'> {}

export const InvertedLinkButton = ({href, children, icon, ...props}: LinkButtonPropsExtended) => {
	const hasIcon = icon ? 'flex items-center' : 'inline-block';

	return (
		<Link
			href={href || '/'}
			className={[
				hasIcon,
				'kkd-button kkd-button--inverted kkd-button--small',
			].join(' ')}
			{...props}
		>
			<span className={icon ? 'inline-block mr-3' : ''}>{children}</span>
			{icon && (
				<Icon
					icon={icon}
					color={'#E2002B'}
					size={18}
					className="inline-block"
				/>
			)}
		</Link>
	);
};
