import React from 'react';
import cn from 'classnames';
import Footer from './footer';
import Meta from './meta';
import LogoRow from './logo-row';
import useLogoImages from '../utils/useLogoImages';
import { ISponsor } from '../models/sponsor';
import FooterLinks from './footer-links';

interface IProps {
	children: React.ReactNode;
	sponsors: ISponsor[];
	customClass?: string;
	smallScreen?: boolean;
	preview?: boolean;
	metaTitle: string;
	metaDesc: string;
}

// Check if context is needed for sponsors
export default function Layout({ children, sponsors, customClass, smallScreen, metaTitle, metaDesc }: IProps) {
	const logoImages = useLogoImages();

	const className = cn({
		[customClass ?? '']: !!customClass,
		'min-h-screen': smallScreen,
	});

	return (
		<>
			<Meta metaTitle={metaTitle} metaDesc={metaDesc} />
			<LogoRow logoImages={logoImages} />
			<div className={className}>
				<main>{children}</main>
			</div>
			<Footer sponsors={sponsors} />
			<FooterLinks />
		</>
	);
}
